@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#main-menu {
	display: none !important;
}

/* iframe { width: 100%; height: 800px; border: 0; overflow: hidden; } */

.embedded-superset iframe {
	width: 100%; 
	height: 800px; 
	border: 0; 
	overflow: hidden;
  }

.styled-heading::before {
  background-color: green;
  content: "";
  display: inline-block;
  font-weight: 500;
  height: 16px;
  margin-right: 10px;
  width: 3px;
}

.styled-heading-large {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 26px;
  margin-bottom: 30px;
}

.styled-heading-small {
	font-size: 15px;
	font-size: 0.9375rem;
	line-height: 21px;
	margin-bottom: 4px;
  }

.icon-preview {
  width: 100%;
  text-align: center;
  /* border: 2px solid #ddd; */
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 12px;
  cursor: pointer;

  transition: border-color 0.15s ease-in-out;
}

.icon-preview:hover,
.icon-preview.active {
  border: 2px solid #4263eb;
}

.styled-heading-small {
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 21px;
  margin-bottom: 4px;
}

.arrow-steps .step {
	font-size: 14px;
	text-align: center;
	color: #666;
	cursor: default;
	margin: 0 3px;
	padding: 10px 10px 10px 30px;
	float: left;
	position: relative;
	background-color: #d9e3f7;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none; 
  transition: background-color 0.2s ease;
}

.arrow-steps .step:after,
.arrow-steps .step:before {
	content: " ";
	position: absolute;
	top: 0;
	right: -17px;
	width: 0;
	height: 0;
	border-top: 19px solid transparent;
	border-bottom: 17px solid transparent;
	border-left: 17px solid #d9e3f7;	
	z-index: 2;
  transition: border-color 0.2s ease;
}

.arrow-steps .step:before {
	right: auto;
	left: 0;
	border-left: 17px solid #fff;	
	z-index: 0;
}

.arrow-steps .step:first-child:before {
	border: none;
}

.arrow-steps .step:first-child {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.arrow-steps .step span {
	position: relative;
}

.arrow-steps .step span:before {
	opacity: 0;
	content: "✔";
	position: absolute;
	top: -2px;
	left: -20px;
}

.arrow-steps .step.done span:before {
	opacity: 1;
	-webkit-transition: opacity 0.3s ease 0.5s;
	-moz-transition: opacity 0.3s ease 0.5s;
	-ms-transition: opacity 0.3s ease 0.5s;
	transition: opacity 0.3s ease 0.5s;
}

.arrow-steps .step.current {
	color: #fff;
	background-color: #23468c;
}

.arrow-steps .step.current:after {
	border-left: 17px solid #23468c;	
}

.request-popup .leaflet-popup-content-wrapper {
	background-color: #ffffff;
    border-radius: 11px;
    box-shadow: 0px 4px 9px #00000040;
	min-width: 350px;
  }
