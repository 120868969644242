.card {
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-size: cover;
    background-position: center;
  }
  
  .title {
    color: black;
    line-height: 1.2;
    margin-top: var(--mantine-spacing-xs);
  }